<template>
  <InfoWraper>
    <router-link :to="`/account/${pointAccount.id}/page/1`" v-if="pointAccount" style="line-height: 30px">
      <Status
        status="created"
        :text="`${i18n.n(+pointAccount.attributes.amount, 'decimal')} ${pointAccount.attributes.amount_currency}`"
      />
    </router-link>

    <Notification />
    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click">
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <figure class="user-dropdwon__info" v-if="userPersonalData">
                <sdHeading as="h5">
                  {{ userPersonalData.last_name }} {{ userPersonalData.first_name }} {{ userPersonalData.middle_name }}
                </sdHeading>
                <div class="user-subscription" v-if="subscriptionData">
                  <div class="user-subscription__title">
                    {{ i18n.t('profileView.subscription.title') }}:
                    <b>{{ i18n.t(`profileView.subscription.kinds.${subscriptionData.kind}`) }}</b>
                  </div>
                  <div class="user-subscription__expiration" v-if="subscriptionData.kind != 'vip'">
                    {{ i18n.t('profileView.subscription.expiration') }}: <b>{{ subscriptionData.expiration_at }}</b>
                  </div>
                </div>
              </figure>
              <ul class="user-dropdwon__links">
                <li>
                  <router-link to="/profile/view">
                    <sdFeatherIcons type="user" /> {{ i18n.t('menuItems.profile') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/settings/subscription">
                    <sdFeatherIcons type="dollar-sign" /> {{ i18n.t('menuItems.subscription') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/settings/security/page/1">
                    <sdFeatherIcons type="shield" /> {{ i18n.t('menuItems.security') }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/profile/settings/global">
                    <sdFeatherIcons type="settings" /> {{ i18n.t('menuItems.settings') }}
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'two-factor' }">
                    <sdFeatherIcons type="lock" />
                    {{ i18n.t('profileView.TwoFac') }}
                  </router-link>
                </li>
              </ul>
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#">
                <LogoutOutlined /> {{ i18n.t('menuItems.signOut') }}
              </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar
            src="https://cdn0.iconfinder.com/data/icons/elasto-online-store/26/00-ELASTOFONT-STORE-READY_user-circle-512.png"
          />
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper, /* NavAuth */ UserDropDwon } from './auth-info-style';
import Notification from './Notification';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { LogoutOutlined } from '@ant-design/icons-vue';
import Status from '@/components/elements/Status';

export default {
  name: 'AuthInfo',
  components: {
    InfoWraper,
    UserDropDwon,
    Notification,
    LogoutOutlined,
    Status,
  },
  data() {
    return {
      flag: 'english',
    };
  },
  setup() {
    const i18n = useI18n();
    const { state, dispatch } = useStore();
    const userPersonalData = computed(() => state.profile.userData);
    const subscriptionData = computed(() => state.profile.subscription);
    const pointAccount = computed(() => (state.accounts.data ? state.accounts.data[3] : false));

    const SignOut = (e) => {
      e.preventDefault();
      dispatch('auth/logout');
    };

    return {
      i18n,
      userPersonalData,
      pointAccount,
      SignOut,
      subscriptionData,
    };
  },
  methods: {
    onFlagChangeHandle: function (value) {
      this.flag = value;
    },
  },
};
</script>

<style scoped lang="scss">
.user-dropdwon {
  &__info {
    flex-direction: column;
  }
  .user-subscription {
    font-size: 14px;
    &__title {
      //font-size: 14px;
    }
    &__expiration {
      //font-weight: 600;
    }
  }
}
</style>
