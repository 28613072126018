<template>
  <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
    <a-menu-item @click="toggleCollapsed" key="start">
      <router-link :to="{ name: 'start' }">
        <sdFeatherIcons type="home" />
        <span>
          {{ i18n.t('menuItems.startPage') }}
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="accounts">
      <router-link :to="{ name: 'accounts' }">
        <sdFeatherIcons type="briefcase" />
        <span>
          {{ i18n.t('menuItems.accounts') }}
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="transactions">
      <router-link :to="{ name: 'transactions' }">
        <sdFeatherIcons type="globe" />
        <span>
          {{ i18n.t('menuItems.transactions') }}
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="trading-public">
      <router-link :to="{ name: 'trading', params: { type: 'public' } }">
        <sdFeatherIcons type="rss" />
        <span>
          {{ i18n.t('menuItems.public') }}
        </span>
      </router-link>
    </a-menu-item>
    <a-sub-menu key="productsCompany">
      <template v-slot:title>
        <sdFeatherIcons type="divide" /> <span> {{ i18n.t('menuItems.products-company') }} </span>
      </template>
      <a-menu-item @click="toggleCollapsed" key="trust-products">
        <router-link :to="{ name: 'trust-products' }">
          <sdFeatherIcons type="activity" />
          <span>
            {{ i18n.t('menuItems.trust-products') }}
          </span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu key="tradingAccounts">
      <template v-slot:title>
        <sdFeatherIcons type="divide" /> <span> {{ i18n.t('menuItems.trading-accounts') }} </span>
      </template>
      <a-menu-item @click="toggleCollapsed" key="trading-real">
        <router-link :to="{ name: 'trading-accounts', params: { type: 'real' } }">
          <sdFeatherIcons type="activity" />
          <span>
            {{ i18n.t('menuItems.real') }}
          </span>
        </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="trading-demo">
        <router-link :to="{ name: 'trading-accounts', params: { type: 'demo' } }">
          <sdFeatherIcons type="tool" />
          <span>
            {{ i18n.t('menuItems.demo') }}
          </span>
        </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="trading-invest">
        <router-link :to="{ name: 'trading', params: { type: 'invest' } }">
          <sdFeatherIcons type="radio" />
          <span>
            {{ i18n.t('menuItems.invest') }}
          </span>
        </router-link>
      </a-menu-item>
      <a-menu-item @click="toggleCollapsed" key="mt5-terminal">
        <router-link :to="{ name: 'mt5-terminal' }">
          <sdFeatherIcons type="hard-drive" />
          <span>
            {{ i18n.t('menuItems.mt5-terminal') }}
          </span>
        </router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item @click="toggleCollapsed" key="contests">
      <router-link to="/contests/">
        <sdFeatherIcons type="star" />
        <span>
          {{ i18n.t('menuItems.contests') }}
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="set-wallet">
      <router-link :to="{ name: 'set-wallet', params: { pageNum: 1 } }">
        <sdFeatherIcons type="credit-card" />
        <span>
          {{ i18n.t('menuItems.wallets') }}
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="partners">
      <router-link to="/partners/">
        <sdFeatherIcons type="users" />
        <span>
          {{ i18n.t('menuItems.partners') }}
        </span>
      </router-link>
    </a-menu-item>
    <a-menu-item @click="toggleCollapsed" key="solar-feed">
      <router-link :to="{ name: 'solar-feed' }">
        <sdFeatherIcons type="sunrise" />
        <span>
          {{ i18n.t('menuItems.solar-feed') }}
        </span>
      </router-link>
    </a-menu-item>
    <!--<a-menu-item @click="toggleCollapsed" key="faq">
      <router-link :to="{ name: 'faq' }">
        <sdFeatherIcons type="help-circle" />
        <span>
          {{ i18n.t('menuItems.FAQ') }}
        </span>
      </router-link>
    </a-menu-item>-->
    <!--<a-menu-item @click="toggleCollapsed" key="education" v-if="locale === 'ru'">
      <a href="https://edu.mthub.io/" target="_blank">
        <sdFeatherIcons type="book" />
        <span>
          {{ i18n.t('menuItems.education') }}
        </span>
      </a>
    </a-menu-item>-->
  </a-menu>
</template>

<script>
import { computed, reactive, ref, toRefs, watch, watchEffect } from 'vue';
import VueTypes from 'vue-types';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'AsideItems',
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.themeLayout.data);
    const mode = ref('inline');
    const { events } = toRefs(props);
    //eslint-disable-next-line
    const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } =
      events.value;
    const i18n = useI18n();
    const locale = computed(() => i18n.locale.value);

    const router = computed(() => useRoute());
    const state = reactive({
      selectedKeys: ['start'],
    });

    watchEffect(() => {
      if (router.value.matched.length) {
        let matched = '';
        const index = router.value.matched.length - 1;

        if (index) {
          matched = router.value.matched[index].name;
        }

        state.selectedKeys = [matched];
        state.openKeys = [matched, 'tradingAccounts', 'productsCompany'];
        state.preOpenKeys = [matched];
        if (matched && matched.includes('trading')) {
          state.selectedKeys = ['trading-' + router.value.params.type];
        }
      }
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );

    return {
      mode,
      ...toRefs(state),
      locale,
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
      i18n,
    };
  },
};
</script>
