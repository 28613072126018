<template>
  <div class="notification">
    <sdPopover placement="bottomLeft" v-model:visible="visible" action="click">
      <template v-slot:content>
        <AtbdTopDropdwon class="atbd-top-dropdwon">
          <sdHeading as="h5" class="atbd-top-dropdwon__title">
            <span class="title-text">{{ i18n.t('notifications.notifications') }}</span>
            <a-badge class="badge-success" :count="newNotifications" />
          </sdHeading>
          <perfect-scrollbar
            :options="{
              wheelSpeed: 1,
              swipeEasing: true,
              suppressScrollX: true,
            }"
            @scroll.passive="scrollNotification"
          >
            <ul class="notification-list">
              <li v-for="(notification, idx) of notifications" :key="notification.id">
                <a to="#" @mouseover.once="readNotification(notification.attributes.read_at, notification.id, idx)">
                  <div class="atbd-top-dropdwon__content notifications">
                    <div class="notification-content d-flex">
                      <div class="notification-text">
                        <sdHeading as="h5">{{ notification.attributes.message }} </sdHeading>
                        <p>{{ i18n.d(notification.attributes.created_at, 'long') }}</p>
                      </div>
                      <div class="notification-status">
                        <a-badge dot v-if="!notification.attributes.read_at" />
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </perfect-scrollbar>
          <router-link class="btn-seeAll" to="/notifications/1" @click="hide">
            {{ i18n.t('notifications.incomingActivity') }}
          </router-link>
        </AtbdTopDropdwon>
      </template>
      <a-badge :dot="newNotifications > 0" :offset="[-8, -5]">
        <a to="#" class="head-example">
          <sdFeatherIcons type="bell" size="20" />
        </a>
      </a-badge>
    </sdPopover>
  </div>
</template>
<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { AtbdTopDropdwon } from './auth-info-style';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
const Notification = {
  name: 'Notification',
  components: {
    AtbdTopDropdwon,
    PerfectScrollbar,
  },
  setup() {
    const { state, dispatch } = useStore();
    const perPage = ref(10);
    const notifications = computed(() => state.notifications.tosterNotifications);
    const newNotifications = computed(() => state.notifications.newNotifications);
    const i18n = useI18n();

    onMounted(() => {
      if (!notifications.value) {
        dispatch('getAllTosterNotifications', perPage.value);
      }
    });
    const visible = ref(false);
    const hide = () => {
      visible.value = false;
    };
    const scrollNotification = (event) => {
      let scrollTop = event.target.scrollTop,
        scrollHeight = event.target.scrollHeight,
        height = event.target.offsetHeight;
      if (scrollTop + 300 > scrollHeight - height && perPage.value <= notifications.value.length) {
        perPage.value += 5;
        dispatch('getAllTosterNotifications', perPage.value);
      }
    };
    const readNotification = (readAt, id, idx) => {
      if (!readAt) {
        dispatch('readNotification', { id, idx });
      }
    };
    return {
      notifications,
      scrollNotification,
      readNotification,
      newNotifications,
      i18n,
      visible,
      hide,
    };
  },
};
export default Notification;
</script>
<style scoped>
.ps {
  height: 200px;
}
</style>
