<template>
  <Div>
    <Header>
      <a-row justify="space-between">
        <a-col :lg="12" :md="12" :sm="12" :xs="6" class="align-center-v navbar-brand">
          <sdButton v-if="!topMenu" @click="toggleCollapsed" type="white">
            <img :src="require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)" alt="menu" />
          </sdButton>
          <router-link :class="topMenu ? 'striking-logo top-menu' : 'striking-logo'" to="/">
            <img style="" :src="require(`../static/img/logo.svg`)" alt="logo" />
          </router-link>
        </a-col>

        <a-col :lg="12" :md="12" :sm="12" :xs="18">
          <AuthInfo />
        </a-col>
      </a-row>
    </Header>
    <Layout class="layout">
      <Layout>
        <template v-if="!topMenu">
          <Sider
            :width="280"
            :style="{
              margin: '0 0 0 0',
              padding: '15px 15px 155px 15px',
              overflowY: 'auto',
              height: '100vh',
              position: 'fixed',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 998,
            }"
            :collapsed="collapsed"
            :theme="!darkMode ? 'light' : 'dark'"
          >
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <AsideItems
                :toggleCollapsed="toggleCollapsedMobile"
                :topMenu="topMenu"
                :rtl="rtl"
                :darkMode="darkMode"
                :events="onEventChange"
              />
            </perfect-scrollbar>
          </Sider>
        </template>
        <Layout class="atbd-main-layout">
          <Content>
            <Suspense>
              <template #default>
                <router-view></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>
            <Footer
              :style="{
                background: 'rgba(255, 255, 255, .90)',
                width: '100%',
                boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
              }"
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  </Div>
</template>
<script>
import { Layout } from 'ant-design-vue';
import { Div } from './style';
import AuthInfo from '../components/utilities/auth-info/Info';
import AsideItems from './Aside';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { computed, ref, onMounted, getCurrentInstance, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { Notification } from 'ant-design-vue';
import { useRouter, useRoute } from 'vue-router';
import { getItem } from '@/utility/localStorageControl';

const Footer = defineAsyncComponent(() => import('./Footer'));

const { Header, Sider, Content } = Layout;

export default {
  name: 'WithAdminLayout',
  components: {
    Div,
    Header,
    Layout,
    Sider,
    Content,
    PerfectScrollbar,
    Footer,
    AuthInfo,
    AsideItems,
  },

  setup() {
    const instance = getCurrentInstance();
    const cable = instance.appContext.config.globalProperties.$cable;
    const i18n = useI18n();
    const router = useRouter();
    const route = useRoute();
    const { dispatch, state } = useStore();

    const collapsed = ref(window.innerWidth <= 1300 && true);
    const hide = ref(true);
    const searchHide = ref(true);
    const customizerAction = ref(false);
    const activeSearch = ref(false);
    let connectTry = 0;

    const rtl = computed(() => state.themeLayout.rtlData);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const connectWithRefreshedToken = () => {
      cable.connection.disconnect();
      cable.connection.connect(`${process.env.VUE_APP_API_WEBSOCKET_URL}?token=${getItem('user').token}`);
    };
    i18n.locale.value = getItem('user').settings.locale;

    const channels = {
      'Noticed::NotificationChannel': {
        connection() {
          connectTry = 0;
        },
        received(data) {
          if (data.type == 'notification') {
            Notification.open({
              message: i18n.t('notifications.' + data.object_type),
              description: data.content,
              duration: 6,
            });
            dispatch('getNewDataNotification', data);
          } else {
            if (data.type == 'update' && data.object.status != 'waiting') {
              dispatch('update' + data.object_type, data.object);
            }
          }
        },
        rejected() {
          if (route.meta.auth) {
            cable.connection.disconnect();
          } else {
            connectWithRefreshedToken();
          }
        },
        disconnected() {
          if (route.meta.auth || connectTry > 8) {
            cable.unsubscribe({
              channel: 'Noticed::NotificationChannel',
            });
          } else {
            connectTry++;
            connectWithRefreshedToken();
          }
        },
      },
      BoxesWinnersChannel: {
        connection() {
          connectTry = 0;
        },
        received(data) {
          dispatch('setNewOpenLootBox', data);
        },
        rejected() {
          if (route.meta.auth) {
            cable.connection.disconnect();
          } else {
            connectWithRefreshedToken();
          }
        },
        disconnected() {
          if (route.meta.auth || connectTry > 8) {
            cable.unsubscribe({
              channel: 'BoxesWinnersChannel',
            });
          } else {
            connectTry++;
            connectWithRefreshedToken();
          }
        },
      },
    };
    const toggleCollapsed = (e) => {
      e.preventDefault();
      collapsed.value = !collapsed.value;
    };
    const handleSearchHide = (search) => {
      searchHide.value = !search;
      hide.value = true;
    };
    const onShowHide = (h) => {
      hide.value = !h;
      searchHide.value = true;
    };
    const toggleSearch = () => {
      activeSearch.value = !activeSearch.value;
    };
    const toggleCollapsedMobile = () => {
      if (innerWidth <= 990) {
        collapsed.value = !collapsed.value;
      }
    };
    const onRtlChange = () => {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
      dispatch('changeRtlMode', true);
    };
    const onLtrChange = () => {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
      dispatch('changeRtlMode', false);
    };
    const modeChangeDark = () => {
      dispatch('changeLayoutMode', true);
    };
    const modeChangeLight = () => {
      dispatch('changeLayoutMode', false);
    };
    const modeChangeTopNav = () => {
      dispatch('changeMenuMode', true);
    };
    const modeChangeSideNav = () => {
      dispatch('changeMenuMode', false);
    };
    onMounted(() => {
      document.title = `${i18n.t('menuItems.' + route.name)} | MTHUB`;
      router.beforeEach((to, from, next) => {
        if (to.name) {
          document.title = `${i18n.t('menuItems.' + to.name)} | MTHUB`;
        } else {
          document.title = `${i18n.t('menuItems.' + to.params.actionType)} | MTHUB`;
        }
        next();
      });

      dispatch('axiosAccountsGetData');

      dispatch('axiosProfileGetData');
      connectWithRefreshedToken();
      cable.subscribe({
        channel: 'Noticed::NotificationChannel',
      });
      cable.subscribe({
        channel: 'BoxesWinnersChannel',
      });
    });
    const onEventChange = {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    };
    return {
      channels,
      i18n,
      collapsed,
      hide,
      searchHide,
      customizerAction,
      activeSearch,
      innerWidth: window.innerWidth,
      rtl,
      darkMode,
      topMenu,
      onEventChange,
      toggleCollapsed,
      connectWithRefreshedToken,
      handleSearchHide,
      toggleCollapsedMobile,
      onShowHide,
      toggleSearch,
    };
  },
};
</script>
<style lang="scss">
.ps {
  height: calc(100vh - 100px);
}
@media only screen and (max-width: 1300px) {
  .ant-popover {
    max-width: 300px;
  }
  .atbd-top-dropdwon {
    max-width: 280px;
  }
}
</style>
